import { playMode } from '@/config'

// 失败重试次数
let retry = 2

const musicPlayer = {
  initAudio(that) {
    const ele = that.audioEle

    // 播放开始
    ele.onplay = () => {
      let timer
      clearTimeout(timer)
      timer = setTimeout(() => {
        that.musicReady = true
      }, 100)
    }
    // 播放暂停
    ele.onpause = () => {
      that.setPlaying(false)
    }
    // 播放时间
    ele.ontimeupdate = () => {
      that.currentTime = ele.currentTime
    }
    // 播放完毕
    ele.onended = () => {
      if (that.mode === playMode.loop) {
        that.loop()
      } else {
        that.next()
      }
    }
    // 播放出错
    ele.onerror = () => {
      if (retry === 0) {
        let toastText = '当前音乐不可播放，自动切换为下一首'
        if (that.playlist.length === 1) {
          toastText = '当前没有可播放的音乐'
        }
        that.$Toast(toastText)
        that.next(true)
      } else {
        retry -= 1
        ele.url = that.currentMusic.url
        ele.load()
      }
    }

    // 音频缓冲进度
    ele.onprogress = () => {
      try {
        if (ele.buffered.length > 0) {
          const duration = that.currentMusic.duration
          let buffered = 0
          ele.buffered.end(0)
          buffered =
            ele.buffered.end(0) > duration ? duration : ele.buffered.end(0)
          that.currentProgress = buffered / duration
        }
      } catch (e) {}
    }
    // 音频进度拖动
    ele.onstalled = () => {
      ele.load()
      that.setPlaying(false)
      let timer
      clearTimeout(timer)
      timer = setTimeout(() => {
        that.setPlaying(true)
      }, 10)
    }
    // 音频加载失败
    ele.onstalled = () => {
      ele.load()
      that.setPlaying(false)
      let timer
      clearTimeout(timer)
      timer = setTimeout(() => {
        that.setPlaying(true)
      }, 10)
    }

    // 加入播放历史
    ele.oncanplay = () => {
      retry = 1
      if (
        that.historyList.length === 0 ||
        that.currentMusic.id !== that.historyList[0].id
      ) {
        that.setHistory(that.currentMusic)
      }
    }

  }
}

export default musicPlayer
