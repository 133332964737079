<template>
  <div class="music">
    <div class="music-content">
      <div class="music-left">
        <music-btn @onClickLyric="handleOpenLyric" />
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive" class="music-list" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" :key="$route.path" class="music-list" />
      </div>
      <div class="music-right" :class="{ show: lyricVisible }">
        <div class="close-lyric music-btn" @click="handleCloseLyric">关闭歌词</div>
        <music-lyric ref="lyric" :lyric="lyric" :nolyric="nolyric" :lyric-index="lyricIndex" />
      </div>
    </div>

    <!--播放器-->
    <div class="music-bar" :class="{ disable: !musicReady || !currentMusic.id }">
      <!-- 控制 -->
      <div class="music-bar-btns">
        <music-icon class="pointer" type="prev" :size="36" title="上一首" @click="prev" />
        <div class="control-play pointer" title="播放/暂停" @click="play">
          <music-icon :type="playing ? 'pause' : 'play'" :size="24" />
        </div>
        <music-icon class="pointer" type="next" :size="36" title="下一首" @click="next" />
      </div>
      <div class="music-music">
        <div class="music-bar-info">
          <template v-if="currentMusic && currentMusic.id">
            {{ currentMusic.name }}
            <span>- {{ currentMusic.singer }}</span>
          </template>
          <template v-else>LGMusic-在线音乐播放器</template>
        </div>
        <div v-if="currentMusic.id" class="music-bar-time">
          {{ currentTime | format }}/{{ currentMusic.duration % 3600 | format }}
        </div>
        <music-progress class="music-progress" :percent="percentMusic" :percent-progress="currentProgress" @percentChange="progressMusic"
          @percentChangeEnd="progressMusicEnd" />
      </div>

      <!-- 模式 -->
      <music-icon class="icon-color pointer mode" :type="getModeIconType()" :title="getModeIconTitle()" :size="30" @click="modeChange" />

      <!-- 评论 -->
      <music-icon class="icon-color pointer comment" type="comment" title="热门评论" :size="30" @click="openComment" />

      <!-- 下载 -->
      <music-icon class="icon-color pointer download" type="ic_get_app" title="下载歌曲" :size="30" @click="downloadMusic"/>

      <!-- 音量 -->
      <div class="music-bar-volume" title="调节音量">
        <music-volume :volume="volume" @volumeChange="volumeChange" />
      </div>
    </div>

    <!--遮罩-->
    <div class="music-bg" :style="{ backgroundImage: picUrl }"></div>
    <div class="music-mask"></div>
  </div>
</template>

<script>
  import musicPlayer from './player'
  import { getLyric } from 'api'
  import {
    randomSortArray,
    parseLyric,
    format,
    silencePromise
  } from '@/utils/util'
  import {
    defaultVolume,
    playMode,
    defaultBG
  } from '@/config'
  import {
    getVolume,
    setVolume
  } from '@/utils/storage'
  import {
    mapGetters,
    mapMutations,
    mapActions
  } from 'vuex'

  import MusicProgress from 'components/music-common/progress/music-progress'
  import MusicBtn from 'components/music-btn/music-btn'
  import MusicLyric from 'components/music-lyric/music-lyric'
  import MusicVolume from 'components/music-volume/music-volume'

  export default {
    name: 'Music',
    components: {
      MusicProgress,
      MusicBtn,
      MusicLyric,
      MusicVolume
    },
    filters: {
      // 时间格式化
      format
    },
    data() {
      const volume = getVolume()
      return {
        musicReady: false, // 是否可以使用播放器
        currentTime: 0, // 当前播放时间
        currentProgress: 0, // 当前缓冲进度
        lyricVisible: false, // 移动端歌词显示
        lyric: [], // 歌词
        nolyric: false, // 是否有歌词
        lyricIndex: 0, // 当前播放歌词下标
        isMute: false, // 是否静音
        volume: defaultVolume // 音量大小
      }
    },
    computed: {
      picUrl() {
        return this.currentMusic.id && this.currentMusic.image ?
          `url(${this.currentMusic.image}?param=300y300)` :
          `url(${defaultBG})`
      },
      percentMusic() {
        const duration = this.currentMusic.duration
        return this.currentTime && duration ? this.currentTime / duration : 0
      },
      ...mapGetters([
        'audioEle',
        'mode',
        'playing',
        'playlist',
        'orderList',
        'currentIndex',
        'currentMusic',
        'historyList'
      ])
    },
    watch: {
      currentMusic(newMusic, oldMusic) {
        if (!newMusic.id) {
          this.lyric = []
          return
        }
        if (newMusic.id === oldMusic.id) {
          return
        }
        this.audioEle.src = newMusic.url
        // 重置相关参数
        this.lyricIndex = this.currentTime = this.currentProgress = 0
        silencePromise(this.audioEle.play())
        this.$nextTick(() => {
          this._getLyric(newMusic.id)
        })
      },
      playing(newPlaying) {
        const audio = this.audioEle
        this.$nextTick(() => {
          newPlaying ? silencePromise(audio.play()) : audio.pause()
          this.musicReady = true
        })
      },
      currentTime(newTime) {
        if (this.nolyric) {
          return
        }
        let lyricIndex = 0
        for (let i = 0; i < this.lyric.length; i++) {
          if (newTime > this.lyric[i].time) {
            lyricIndex = i
          }
        }
        this.lyricIndex = lyricIndex
      },
      $route() {
        this.lyricVisible = false
      }
    },
    mounted() {
      this.$nextTick(() => {
        musicPlayer.initAudio(this)
        this.volumeChange(this.volume)
      })
    },
    methods: {
      // 上一曲
      prev() {
        if (!this.musicReady) {
          return
        }
        if (this.playlist.length === 1) {
          this.loop()
        } else {
          let index = this.currentIndex - 1
          if (index < 0) {
            index = this.playlist.length - 1
          }
          this.setCurrentIndex(index)
          if (!this.playing && this.musicReady) {
            this.setPlaying(true)
          }
          this.musicReady = false
        }
      },
      // 播放暂停
      play() {
        if (!this.musicReady) {
          return
        }
        this.setPlaying(!this.playing)
      },
      // 下一曲
      // 当 flag 为 true 时，表示上一曲播放失败
      next(flag = false) {
        if (!this.musicReady) {
          return
        }
        const {
          playlist: {
            length
          }
        } = this
        if (
          (length - 1 === this.currentIndex && this.mode === playMode.order) ||
          (length === 1 && flag)
        ) {
          this.setCurrentIndex(-1)
          this.setPlaying(false)
          return
        }
        if (length === 1) {
          this.loop()
        } else {
          let index = this.currentIndex + 1
          if (index === length) {
            index = 0
          }
          if (!this.playing && this.musicReady) {
            this.setPlaying(true)
          }
          this.setCurrentIndex(index)
          this.musicReady = false
        }
      },
      // 循环
      loop() {
        this.audioEle.currentTime = 0
        silencePromise(this.audioEle.play())
        this.setPlaying(true)
        if (this.lyric.length > 0) {
          this.lyricIndex = 0
        }
      },
      // 修改音乐显示时长
      progressMusic(percent) {
        this.currentTime = this.currentMusic.duration * percent
      },
      // 修改音乐进度
      progressMusicEnd(percent) {
        this.audioEle.currentTime = this.currentMusic.duration * percent
      },
      // 切换播放顺序
      modeChange() {
        const mode = (this.mode + 1) % 4
        this.setPlayMode(mode)
        if (mode === playMode.loop) {
          return
        }
        let list = []
        switch (mode) {
          case playMode.listLoop:
          case playMode.order:
            list = this.orderList
            break
          case playMode.random:
            list = randomSortArray(this.orderList)
            break
        }
        this.resetCurrentIndex(list)
        this.setPlaylist(list)
      },
      // 修改当前歌曲索引
      resetCurrentIndex(list) {
        const index = list.findIndex(item => {
          return item.id === this.currentMusic.id
        })
        this.setCurrentIndex(index)
      },
      // 打开音乐评论
      openComment() {
        if (!this.currentMusic.id) {
          this.$Toast('还没有播放任何歌曲！')
          return false
        }
        this.$router.push(`/music/comment/${this.currentMusic.id}`)
      },
      // 下载音乐链接
      downloadMusic() {
        if (!this.currentMusic.id) {
          this.$Toast('还没有播放歌曲哦！')
          return false
        }
        window.open(`https://music.163.com/song/media/outer/url?id=${this.currentMusic.id}.mp3`,'_blank')
      },
      // 修改音量大小
      volumeChange(percent) {
        percent === 0 ? (this.isMute = true) : (this.isMute = false)
        this.volume = percent
        this.audioEle.volume = percent
        setVolume(percent)
      },
      // 播放模式图标
      getModeIconType() {
        return {
          [playMode.listLoop]: 'loop',
          [playMode.order]: 'sequence',
          [playMode.random]: 'random',
          [playMode.loop]: 'loop-one'
        } [this.mode]
      },
      // 播放模式
      getModeIconTitle() {
        return {
          [playMode.listLoop]: '列表循环',
          [playMode.order]: '顺序播放',
          [playMode.random]: '随机播放',
          [playMode.loop]: '单曲循环'
        } [this.mode]
      },
      // 查看歌词
      handleOpenLyric() {
        this.lyricVisible = true
        this.$nextTick(() => {
          this.$refs.lyric.clacTop()
        })
      },
      // 关闭歌词
      handleCloseLyric() {
        this.lyricVisible = false
      },
      // 获取歌词
      _getLyric(id) {
        getLyric(id).then(res => {
          if (res.nolyric) {
            this.nolyric = true
          } else {
            this.nolyric = false
            this.lyric = parseLyric(res.lrc.lyric)
          }
          silencePromise(this.audioEle.play())
        })
      },
      ...mapMutations({
        setPlaying: 'SET_PLAYING',
        setPlaylist: 'SET_PLAYLIST',
        setCurrentIndex: 'SET_CURRENTINDEX'
      }),
      ...mapActions(['setHistory', 'setPlayMode'])
    }
  }
</script>

<style lang="less">
  .music {
    padding: 75px 25px 25px 25px;
    width: 100%;
    max-width: 1750px;
    margin: 0 auto;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .music-content {
      display: flex;
      width: 100%;
      height: calc(~'100% - 80px');

      .music-left {
        flex: 1;
        height: 100%;
        overflow: hidden;

        .music-list {
          height: calc(~'100% - 60px');
        }
      }

      .music-right {
        position: relative;
        width: 310px;
        margin-left: 10px;

        .close-lyric {
          position: absolute;
          top: 0;
          z-index: 1;
          cursor: pointer;
        }
        .close-lyric {
          display: inline-block;
          height: 30px;
          box-sizing: border-box;
          margin-right: 8px;
          padding: 0 10px;
          border: 2px solid rgba(215, 155, 205, 0.7);
          color: @btn_color;
          border-radius: 20px;
          font-size: 13px;
          line-height: 30px;
          overflow: hidden;
          transition: all 0.6s ease-in-out;
          cursor: pointer;
          &:nth-last-of-type(1) {
            margin: 0;
          }
          &:hover,
          &.active {
            border-width: 2px;
            border-color: rgba(215, 155, 205, 1.0);
            color: @btn_color_active;
          }
        }
      }
    }

    .music-bar {
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      box-sizing: border-box;
      padding-bottom: 15px;
      color: #fff;

      &.disable {
        pointer-events: none;
        opacity: 0.6;
      }

      .icon-color {
        color: #fff;
      }

      .music-bar-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 180px;

        .control-play {
          .flex-center;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          color: #fff;
          background-color: rgba(255, 255, 255, 0.3);

          .icon-bofang101 {
            transform: translateX(2px);
          }
        }
      }

      .flex-center;

      .btn-prev {
        width: 19px;
        min-width: 19px;
        height: 20px;
        background-position: 0 -30px;
      }

      .btn-play {
        width: 21px;
        min-width: 21px;
        height: 29px;
        margin: 0 50px;
        background-position: 0 0;

        &.btn-play-pause {
          background-position: -30px 0;
        }
      }

      .btn-next {
        width: 19px;
        min-width: 19px;
        height: 20px;
        background-position: 0 -52px;
      }

      .music-music {
        position: relative;
        width: 100%;
        flex: 1;
        box-sizing: border-box;
        padding-left: 40px;
        font-size: @font_size_small;
        color: @text_color_active;

        .music-bar-info {
          height: 15px;
          padding-right: 80px;
          line-height: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .music-bar-time {
          position: absolute;
          top: 0px;
          right: 0px;
        }
      }

      .mode,
      .comment,
      .download,
      .music-bar-volume {
        margin-left: 20px;
      }

      // 音量控制
      .volume-wrapper {
        margin-left: 20px;
        width: 150px;
      }
    }

    /*遮罩*/
    .music-mask,
    .music-bg {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }

    .music-mask {
      z-index: -1;
      background-color: @mask_color;
    }

    .music-bg {
      z-index: -2;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      filter: blur(12px);
      opacity: 0.7;
      transition: all 0.8s;
      transform: scale(1.1);
    }

    @media (min-width: 960px) {
      .close-lyric {
        display: none !important;
      }
    }

    //当屏幕小于960时
    @media (max-width: 960px) {
      .music-right {
        display: none;

        &.show {
          display: block;
          margin-left: 0;
          width: 100%;
        }
      }
    }

    //当屏幕小于768时
    @media (max-width: 768px) {
      & {
        padding: 75px 15px 5px 15px;
      }

      .music-content .music-left {
        .music-list {
          font-size: @font_size_medium;
        }
      }

      .music-bar {
        .download,
        .music-bar-info span,
        .music-bar-volume .progress {
          display: none;
        }
      }
    }

    //当屏幕小于520时
    @media (max-width: 520px) {
      .music-bar {
        position: relative;
        flex-direction: column;

        .music-bar-btns {
          width: 60%;
          margin-top: 15px;
          order: 2;
        }

        .music-music {
          padding-left: 0;
          order: 1;
        }

        &>i.mode {
          position: absolute;
          top: 40px;
          left: 5px;
          margin: 0;
        }

        .comment {
          position: absolute;
          top: 40px;
          right: 5px;
        }

        .download{
          display: none;
        }

        .music-bar-volume {
          display: none;
        }
      }
    }
  }
</style>
